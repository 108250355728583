@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2em;
  font-weight: bold;
}

h2 {
  font-size: 1.5em;
  font-weight: bold;
}

h3 {
  font-size: 1.17em;
  font-weight: bold;
}

h4 {
  font-weight: bold;
}

h5 {
  font-size: 0.83em;
  font-weight: bold;
}

ol {
  list-style-type: demical;
}

ol li {
  list-style-position: inside;
}

ul {
  list-style-type: disc;
}

ul li {
  list-style-position: inside;
}

.hidden-scroll {
  scrollbar-width: none;
}

/* 隐藏垂直滚动条 */
.hidden-scroll::-webkit-scrollbar {
  width: 0;
}

/* 隐藏水平滚动条 */
.hidden-scroll::-webkit-scrollbar {
  height: 0;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-btn-primary:hover {
  background-color: #841eec !important;
  opacity: 0.9;
}

.ant-btn-default:hover {
  border-color: #841eec !important;
  color: #841eec !important;
}

.ant-input-disabled {
  color: #98939d !important;
}

.anticon svg {
  vertical-align: baseline;
}

.ant-btn-primary {
  background-color: #841eec;
}

.ant-btn-default,
.ant-btn-dashed {
  background-color: white;
}

.radio-style::before {
  display: none !important;
}

.ant-modal-header {
  margin-bottom: 0 !important;
  box-sizing: border-box;
}

.ant-modal-header::after {
  content: "";
  height: 1px;
  width: calc(100% + 48px);
  margin-left: -24px;
  background-color: #f0ebf5;
  display: block;
  margin-top: 21px;
  margin-bottom: 32px;
}

.ant-modal-footer {
  margin-top: 0 !important;
}

.ant-modal-footer::before {
  display: block;
  content: "";
  height: 1px;
  width: calc(100% + 48px);
  margin-left: -24px;
  background-color: #f0ebf5;
  margin-top: 40px;
  margin-bottom: 20px;
}

.custom-tree .ant-tree-treenode {
  padding-left: 14px;
}

.custom-tree .ant-tree-treenode:hover::before {
  background-color: #f9f7fa !important;
}

.custom-tree .ant-tree-treenode-selected::before {
  background-color: #f9f7fa !important;
}

.custom-select .ant-select-selector {
  font-size: 12px !important;
  padding: 0 6px !important;
  color: #68636d !important;
}

.custom-select-inbox .ant-select-selector {
  font-size: 12px !important;
  padding: 0 6px !important;
  color: #841eec !important;
}
.custom-select-inbox .ant-select-arrow {
  color: #841eec !important;
  font-size: 10px !important;
}
.sign-box .custom-select .ant-select-selector {
  font-size: 14px !important;
}

.custom-tree {
  color: #37323c !important;
}

.custom-tree .ant-tree-node-selected {
  color: #37323c !important;
}

.custom-tree .ant-tree-switcher {
  color: #37323c !important;
}

.table-box .ant-table-cell::before {
  display: none;
}

.ant-dropdown-menu {
  width: 90px !important;
}

.br {
  border: 1px#ff0000 solid;
}

.editor-content {
  white-space: pre-wrap;
}
.aiNew{
  width: 32px;
  height: 14px;
  line-height: 13px;
  background: linear-gradient(118deg, #7AA0FF 0%, #9755FF 54%, #9B37FF 100%);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  font-weight: 500;
  font-size: 10px;
  color: #FFFFFF;
  margin-left: 8px;
}